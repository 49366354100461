import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import lazyImport from "./lazyImport";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { JumpCircleLoading } from 'react-loadingg';

import "./App.scss";

const Clients = lazyImport("./screens/Clients");
const Services = lazyImport("./screens/Services");
const About = lazyImport("./screens/About");
const Home = lazyImport("./screens/Home");
const Contact = lazyImport("./screens/Contact");

const LoadingMessage = () => <JumpCircleLoading />;

const App = () => {
  return (
    <Router>
      <div>
        <Suspense fallback={<LoadingMessage />}>
          <Switch>
            <Route path="/services">
              <Services />
            </Route>

            <Route path="/clients">
              <Clients />
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route>
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </div>
      <a
        className="whatsapp-widget"
        rel="noreferrer"
        href="https://api.whatsapp.com/send?phone=46767086309"
        target="_blank"
      >
        <WhatsAppIcon id="whatsapp-icon" />
      </a>
    </Router>
  );
};

export default App;
